import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Account } from "../../../service/Account";

export default function PlayerEdit () {
  const { tokenUser } = useParams();
  const [player, setPlayer] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleChange = (e) => {
    setPlayer({
      ...player,
      firstname: e.target.value,
    });
  };

  const handleSubmit = () => {
    axios.put(`/player/${tokenUser}/`, {
      firstname: player.firstname
    }, {
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `bearer ${Account.getToken()}`,
      },
    });
  };
  useEffect(() => {
    async function getPlayer() {
      const response = await axios.get(`/player/${tokenUser}/`, {
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `bearer ${Account.getToken()}`,
        },
      });
      console.log('firstname: ' + response.data.firstname);
      setPlayer(response.data);
      setLoading(false);
    }
    return () => getPlayer();
  }, [tokenUser]);

  if (loading) {
    return <h1>Merci de patienter</h1>;
  }

  return (
    <div className="container">
      <h2>{player.firstname}</h2>
      <input type="text" value={player.firstname} onChange={handleChange} />
      <input type="submit" name="Enregistrer" onClick={handleSubmit} />
      <div>
        <Link to="/admin/player">Retour</Link>
        <br />
        <br />
        <Link to="/admin/player/add">Add</Link>
        <br />
        <Link to="/admin/player/edit">Edit</Link>
      </div>
    </div>
  );
};

// export default PlayerEdit;
