import React from "react";
import { Outlet } from "react-router-dom";
import HeaderAdmin from "../../component/HeaderAdmin";

const LayoutAdmin = () => {
  return (
    <>
      <HeaderAdmin />
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default LayoutAdmin;
