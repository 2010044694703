import React, { useState } from "react";
import axios from "axios";
import "./Auth.css";
import { Account } from "../../../service/Account";
import { useNavigate } from "react-router-dom";

const Auth = () => {
  const [playername, setPlayername] = useState("admin@badapi.com");
  const [password, setPassword] = useState("adminpassword");
  const navigate = useNavigate();

  const onSubmit = () => {
    axios
      .post(
        "/login-auth",
        {
          username: playername,
          password: password,
        },
        {
          mode: "cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        console.log('token: ' + response.data.token);
        Account.saveToken(response.data.token);
        navigate("/admin");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <h1>Identification</h1>
      <div className="container">
        <div className="form-group">
          <label className="bold">Player</label>
          <input
            type="text"
            name="playername"
            value={playername}
            className="form-control input"
            onChange={(e) => setPlayername(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label className="bold">Password</label>
          <input
            type="text"
            name="password"
            value={password}
            className="form-control "
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <button onClick={onSubmit} className="btn btn-success mt-20">
            Valider
          </button>
        </div>
      </div>
    </>
  );
};

export default Auth;
