import React, { useState } from "react";
import Button from "../../component/Button";

function useButton () {
  const [count, setCount] = useState(0)
  const increment = () => {
    setCount(c => c + 1)
  }
  return [count, increment]
}

const Home = () => {
  return (
    <div className="container">
      <div>Home</div>
      <Button value="Super bouton" useButton={useButton} />
    </div>
  );
};

export default Home;
