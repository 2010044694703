import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Account } from "../../../service/Account";

const Player = () => {
  let token = Account.getToken();
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [searchParams] = useSearchParams();
  // const tokenParam = searchParams.get('token_param');

  const searchParam = new URLSearchParams(document.location.search);
  console.log("token avant: " + token);
  console.log("tokenParam: " + searchParam.get("token_param"));
  token = searchParam.get("token_param")
    ? searchParam.get("token_param")
    : token;
  console.log("token apres: " + token);
  useEffect(() => {
    console.log("in useEffect()");
    async function getPlayers() {
      const response = await axios.get("/player/", {
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `bearer ${token}`,
        },
      });
      console.log(response);
      console.log(response.data);
      setPlayers(response.data);
      setLoading(false);
    }
    getPlayers();
  }, [token]);

  if (loading) {
    return <h1>Merci de patienter</h1>;
  }

  return (
    <div>
      <h1>Players</h1>
      <div>
        <table className="table table-striped table-border">
          <thead>
            <tr>
              <th>Name</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {players.map((player) => {
              return (
                <tr key={player.token}>
                  <td>{player.firstname}</td>
                  <td>
                    <Link to={`/admin/player/edit/${player.token}`}>
                      Modifier
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <Link to="/admin">Retour</Link>
      <br />
      <br />
      <Link to="/admin/player/add">Add</Link>
      <br />
      <Link to="/admin/player/edit">Edit</Link>
    </div>
  );
};

export default Player;
