import React from "react";
import { Link } from "react-router-dom";

const PlayerAdd = () => {
  return (
    <div>
      <h1>Player Add</h1>
      <Link to="/admin/player">Retour</Link>
      <br /><br />
      <Link to="/admin/player/add">Add</Link>
      <br />
      <Link to="/admin/player/edit">Edit</Link>
    </div>
  );
};

export default PlayerAdd;
