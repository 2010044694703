const saveToken = (token) => {
  localStorage.setItem("token", token);
};

const getToken = () => {
  return localStorage.getItem("token");
};

const logout = () => {
  localStorage.removeItem("token");
};

const isLogged = () => {
  return !!getToken();
};

export const Account = { saveToken, logout, isLogged, getToken };
