import React from "react";
import { Route, Routes } from "react-router-dom";
import { LayoutFront, Home, Contact, Team, Error } from ".";

const RoutingFront = () => {
  return (
    <Routes>
      <Route element={<LayoutFront />}>
        <Route index element={<Home />} />
        <Route path="team" element={<Team />} />
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
};

export default RoutingFront;
