import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  LayoutAdmin,
  HomeAdmin,
  Player,
  Error,
  PlayerAdd,
  PlayerEdit,
  Tournament,
} from ".";

const RoutingBack = () => {
  return (
    <Routes>
      <Route element={<LayoutAdmin />}>
        <Route index element={<HomeAdmin />} />
        <Route path="tournament">
          <Route path="" element={<Tournament />} />
        </Route>
        <Route path="player">
          <Route path="" element={<Player />} />
          <Route path="add" element={<PlayerAdd />} />
          <Route path="edit/:tokenUser" element={<PlayerEdit />} />
        </Route>
        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
};

export default RoutingBack;
