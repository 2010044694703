import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Account } from "../../../service/Account";

const Tournament = () => {
  const token = Account.getToken();
  const [tournaments, setTournaments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getTournament() {
      const response = await axios.get("/tournament/",{
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `bearer ${token}`,
        },
      });
      setTournaments(response.data);
      setLoading(false);
    }
    return () => getTournament();
  }, [token]);

  if (loading) {
    return <h1>Merci de patienter</h1>;
  }

  return (
    <div>
      <h1>Tournois</h1>
      <div>
        <table className="table table-striped table-border">
          <thead>
            <tr>
              <th>Name</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {tournaments.map((tournament) => {
              return (
                <tr key={tournament.token}>
                  <td>{tournament.name}</td>
                  <td>
                    {/* <Link to={`/admin/player/edit/${tournament.token}`}>
                      Modifier
                    </Link> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <Link to="/admin">Retour</Link>
    </div>
  );
};

export default Tournament;
