import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RoutingFront from "./page/front/RoutingFront";
import RoutingBack from "./page/back/RoutingBack";
import { AuthGuard } from "./component/AuthGuard";
import Auth from "./page/back/auth/Auth"

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<RoutingFront />} />
          <Route
            path="/admin/*"
            element={
              <AuthGuard>
                <RoutingBack />
              </AuthGuard>
            }
          />
          <Route path="/auth" element={<Auth />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
