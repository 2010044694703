import React from "react";
import { Link } from "react-router-dom";
import { Account } from "../service/Account";
import { useNavigate } from "react-router-dom";

const HeaderAdmin = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    Account.logout();
    navigate("/");
  }
  return (
    <header>
      <nav>
        <ul>
          <li>
            <Link to="/admin/">Accueil</Link>
          </li>
          <li>
            <Link to="/admin/tournament">Tournois</Link>
          </li>
          <li>
            <Link to="/admin/player">Joueurs</Link>
          </li>
          <li>
            <Link to="/">Front</Link>
          </li>
          <li>
            <button className="btn btn-danger" onClick={handleLogout}>Logout</button>
          </li>
        </ul>
      </nav>
    </header>
  );
}
export default HeaderAdmin;