import React from "react";
import { Navigate } from "react-router-dom";
import { Account } from "../service/Account";

export const AuthGuard = ({ children }) => {
  if (!Account.isLogged()) {
    return <Navigate to="/auth" />;
  }
  return children;
};
