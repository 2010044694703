import { React, useState } from "react";

const Button = ({ value }) => {
  const [nbClicked, setNbClicked] = useState(0);

  function handleClick() {
    const nb = nbClicked + 1;
    setNbClicked(nb);
  }

  function getValue() {
    return `${value} ${nbClicked}`;
  }

  return (
    <button className="btn" onClick={() => handleClick()}>
      {getValue()}
    </button>
  );
};

export default Button;
