import React from "react";
import { Account } from "../../service/Account";

const HomeAdmin = () => {
  console.log(Account.getToken());
  return (
    <>
      <div>HomeAdmin</div>
    </>
  );
};

export default HomeAdmin;
